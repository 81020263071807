<template>
    <section class="page-estates-item-cover">
        <div class="page-estates-item-cover__video"
            v-if="youtubeIframeCode"
            v-html="youtubeIframeCode"
            >
        </div>
        <div class="page-estates-item-cover__image"
            v-else
            v-bind:style="{ backgroundImage }"
            >
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-estates-item-cover',
    props: [ 'estate' ],
    computed: {
        youtubeIframeCode() {
            if (!this.estate.video) {
                return null;
            }

            const allows = [
                'accelerometer',
                'autoplay',
                'loop',
                'clipboard-write',
                'encrypted-media',
                'gyroscope',
                'picture-in-picture',
            ];
            let iframeUrl = null;
            let id = null;

            if (this.estate.video.includes('youtu')) {
                const queries = [
                    { title: 'autoplay', value: '1' },
                    { title: 'mute', value: '1' },
                    { title: 'loop', value: '1' },
                ];
                if (this.estate.video.indexOf('/watch?v=') > -1) {
                    id = this.estate.video.match(/(?=\/watch\?v=).*(?=[?&/; ]|$)/)?.[0].substring(9);
                } else if (this.estate.video.indexOf('/youtu.be/') > -1) {
                    id = this.estate.video.match(/(?=\/youtu.be\/).*(?=[?&/; ]|$)/)?.[0].substring(10);
                } else if (this.estate.video.indexOf('/embed/') > -1) {
                    id = this.estate.video.match(/(?=\/embed\/).*?(?=["'?])/)?.[0].substring(7);
                } else {
                    return null;
                }
                iframeUrl = id ? `https://www.youtube.com/embed/${id}?${queries.map(({ title, value }) => `${title}=${value}`).join('&')}&playlist=${id}` : null;
            } else if (this.estate.video.includes('rutube') && (this.estate.video.includes('video') || this.estate.video.includes('embed'))) {
                id = this.estate.video.match(/\/(video|embed)\/([a-f0-9]+)/)?.[0].substring(7);
                iframeUrl = id ? `https://rutube.ru/play/embed/${id}` : null;
                /* Force Rutube to autoplay https://rutube.ru/info/embed/ */
                window.addEventListener('message', this.autoplayRuTube);
            }

            if (!iframeUrl) {
                return null;
            }

            return `<iframe
                id="my-player"
                src="${iframeUrl}"
                title="${this.estate.title} video"
                allow="${allows.join('; ')}"
                allowfullscreen
                ></iframe>`;
        },
        image() {
            if (window.innerWidth > 1280) {
                return utils.common.injectResize(this.estate.image, '1940xAUTO');
            } else if (window.innerWidth > 576) {
                return utils.common.injectResize(this.estate.image, '1200xAUTO');
            } else {
                return utils.common.injectResize(this.estate.image, '720xAUTO');
            }
        },
        backgroundImage() {
            return `url(${this.image})`;
        },
    },
    methods: {
        autoplayRuTube(event) {
            try {
                let message = null;
                if (typeof event.data === 'string') {
                    message = JSON.parse(event.data);
                }
                if (message && message?.type === 'player:ready') {
                    const player = document.getElementById('my-player');
                    if (player) {
                        player.contentWindow.postMessage(JSON.stringify({
                            type: 'player:play',
                            data: {},
                        }), '*');
                        window.removeEventListener('message', this.autoplayRuTube);
                    }
                }
            } catch (e) {}
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-estates-item-cover {
    position: relative;

    display: flex;
    align-items: stretch;
    justify-content: stretch;
    min-height: 100%;
    &__video {
        position: relative;

        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: stretch;
        width: 100%;

        background-color: @color-gray-darkest;
        & /deep/ iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
    &__image {
        flex: 1 1 auto;
        width: 100%;
        padding-top: 73.53%;

        background-color: @color-gray-light;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    @media @media-sm-down {
        &__video {
            & /deep/ iframe {
                min-height: 66.66vw;
            }
        }
        &__image {
            padding-top: 63.88%;
        }
    }
}
</style>
